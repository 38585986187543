import { createSlice } from '@reduxjs/toolkit'

const viewSlice = createSlice({
    name: 'data/modal',
    initialState: {
        modal: false,
        
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.modal = action.payload
        }
    }})

    export const { setIsOpen } = viewSlice.actions
    export default viewSlice.reducer
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
//import mockServer from './mock';
import './locales';

import { initAuth } from 'store/auth/supabaseSlice';

//const environment = process.env.NODE_ENV;

// if (environment !== 'production' && appConfig.enableMock) {
// 	mockServer({ environment })
// }

//mockServer({ environment });

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);
  return (
    <BrowserRouter history={history}>
      <Theme>
        <Layout />
      </Theme>
    </BrowserRouter>
  );
}

export default App;

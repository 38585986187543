import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'

import supabase from "./supabaseSlice"


const reducer = combineReducers({
    session,
    user,
   
    supabase
})

export default reducer
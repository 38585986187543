import { createSlice } from "@reduxjs/toolkit";
import { database } from "utils/supabase/client";


const initialState = {
  user: null,
  error: null,
  isLoading: false,
};

const supabaseSlice = createSlice({
  name: "auth/supabase",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    loginFailure: (state, { payload: error }) => {
      state.user = null;
      state.isLoading = false;
      state.error = error;
    },
    logout: (state) => {
      state.user = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
supabaseSlice.actions;

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const { data, error } = await database.auth.signInWithPassword({
      email,
      password,
    });
    
    if (error) {
      throw new Error(error.message);
    }
    dispatch(loginSuccess(data.user));
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};


export const logoutUser =()=>  async (dispatch) => {
  try {
    const { error } = await database.auth.signOut();


    if (error) {
      throw error;
    }
    dispatch(logout());
  } catch (error) {
    alert(error.error_description || error.message);
  }
};


export const initAuth = () => async (dispatch) => {
  dispatch(loginStart());
  await database.auth.onAuthStateChange((event, session) => {
    
    if (session) {
      dispatch(loginSuccess(session.user));
    } 
    else {
      dispatch(logout());
    }
  });
};

export default supabaseSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'

const projectSlice = createSlice({
    name: 'project/data',
    initialState: {
        data: [],
        
    },
    reducers: {
        setDataProject: (state, action) => {
            state.data = action.payload
        }
    }})

    export const { setDataProject } = projectSlice.actions
    export default projectSlice.reducer